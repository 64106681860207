/* eslint-disable react/prop-types */
import React, { useState } from "react"
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CreateRoom from "./modal/CreateRoom";
import logo from './assets/logo2.png'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
const RoomPanel = ({setRoomName, rooms, setRooms}) => {
    const [ openModal, setOpenModal ] = useState(false)
return (
    <div className="bg-roomColor h-screen shadow-lg flex flex-col justify-between overflow-y-auto">
        <div>
        <div className="flex flex-col items-center">
        <img src={logo} alt="logo"/>
        </div>
        <div className="flex flex-col items-left">
        <p className="text-xl mb-2 text-left pl-3 ">Rooms:</p>
        <ul className="list-reset flex flex-col text-left">
            {rooms.map((room,i)=> {
                return (
                    <li key={i} onClick={()=>setRoomName(room)} className="hover:text-messageColor hover:shadow-sm cursor-pointer border-t  p-3 border-grey flex shadow-md ">
                    <MeetingRoomIcon fontSize="large" className="mr-4 mt-2" />
                 <div>
                 <p className="font-bold">{room}</p>
             <span className="text-sm font-light text-grey-500">0 active</span>
             </div>
             </li>
                )
            })}
        </ul>
        </div>
        </div>
        <div>
        <button onClick={()=>setOpenModal(true)} className=" outline-none text-xl mb-5 mt-5"> <AddCircleOutlineIcon className="mb-1 mr-1"/> Create your room</button>
        </div>     
            {openModal ? <CreateRoom openModal={openModal} setOpenModal={setOpenModal} setRoomName={setRoomName} setRooms={setRooms} rooms={rooms}/> : null }
    </div>
)
}
export default RoomPanel