/* eslint-disable react/prop-types */
import React from "react";
import SendIcon from '@material-ui/icons/Send';
const SendMessage = ({socket,message,setMessage,nickname, roomName}) => {
    const sendMessage = () => {
        socket.emit("chat message",nickname,message,roomName)
        setMessage("")
      }
      const handleKeyPress = (e) =>{
          if(e.key=== 'Enter') {
           setMessage(e.target.value)
           sendMessage()
          }
    
      }
    return (
        <div className="bg-gray-100 p-2 shadow-md rounded">
            <div className="flex">
               <div className="mr-2 mt-1 ">{nickname}</div> 
       <input className="border-b-2 w-full bg-gray-100 border-messageColor outline-none focus:border-b-blue-700 mb-3" placeholder="Start typing message..." onKeyPress={(e)=>handleKeyPress(e)} type="text" value={message} onChange={(e)=>setMessage(e.target.value)}/>
       <button className="text-blue-700 mb-3 ml-1" onClick={sendMessage}><SendIcon fontSize="large" className="rounded-full p-2 text-white bg-messageColor"/></button>
            </div>
        </div>
    )
}
export default SendMessage