import React, { useState } from "react"
import logo from "../components/assets/logo2.png"
import { useHistory } from "react-router"

const Nickname = () => {
    const history = useHistory()

    const [nickname, setNickname ] = useState('')
    const [ msg, setMsg ] = useState()
    const submitNickname = () => {
        if(nickname) {
        history.push({
            pathname: "/chat"
        })
    }
    setMsg('Please choose nickname')
    }
    const handleKeyPress = (e) => {
    if(e.key === 'Enter') {
    setNickname(e.target.value)
    localStorage.setItem("nickname",e.target.value)
    
    submitNickname()
    }
    }
    return (
        <div className="bg-gray-200 w-full h-screen flex justify-center">
            
            <div className="bg-white mt-20 h-2/3 rounded-md shadow-md w-1/3 p-10 m-6 flex flex-col justify-center items-center">
                <img src={logo} className="w-1/4 justify-center" alt="logo"/>
            <p className="text-3xl mb-5">Welcome to <b>Circle</b> Chat</p>
            <p className="text-xl mb-5 mt-5">Please choose your nickname to start chatting...</p>
            <input className="border-b-2 w-full border-messageColor outline-none focus:border-b-blue-700 mb-3" onKeyPress={(e)=>handleKeyPress(e)} type="text" onChange={(e)=>setNickname(e.target.value)}/>
            <button className="bg-messageColor p-3 w-full rounded-md text-white" onClick={submitNickname}>Submit</button>
            <p className="text-red-500">{msg}</p>
            </div>
        </div>
        
    )
}
export default Nickname