import React from 'react'
import MainDashobard from '../src/components/MainDashboard'
import './App.css';
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import Nickname from './components/Nickname';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Nickname}/>
        <Route path="/chat" component={MainDashobard} />
      </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
