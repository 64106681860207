/* eslint-disable react/prop-types */
import React, { useState } from "react"
import Modal from 'react-modal'
import CloseIcon from '@material-ui/icons/Close';
import logo from "../../components/assets/logo2.png"

const CreateRoom = ({ openModal, setOpenModal, setRoomName,setRooms, rooms }) => {
    const [temp, setTemp] = useState('')
    const [ errMsg, setErrMsg ] = useState()
    const closeModal = () => {
        setOpenModal(false)
    }
    const submitRoomName = () => {
      if(temp) { //check if it's empty
        setRoomName(temp)
        setRooms([...rooms, temp])
        setOpenModal(false)
      }
      else {
        setErrMsg('Please enter the room name')
      }

    }
    const handleKeyPress = (e) => {
      if(e.key === 'Enter') {
        if(temp) { //check if it's empty
          setRoomName(temp)
          setRooms([...rooms, temp])
          setOpenModal(false)
        }
        else {
          setErrMsg('Please enter the room name')
        }
        
      }
    }
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      };
return (
    <div className="">
        <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Create Room Modal"
      >
        <div className="text-right mb-4">
        <button onClick={closeModal}><CloseIcon/></button>
        </div>
          <div className="px-8">
        <div className="pb-2 mb-2 text-center justify-center flex flex-col items-center">
        <img src={logo} className="w-1/3 justify-center mb-3" alt="logo"/>
        <p className="text-lg">Please enter the room name:</p>
        <input onKeyPress={(e)=>handleKeyPress(e)}  className="border-b-2 w-full border-messageColor outline-none focus:border-b-blue-700 mb-3" type="text" onChange={(e)=>setTemp(e.target.value)}/>
        <button className="bg-messageColor p-2 w-full rounded-md text-white" onClick={submitRoomName}>Submit</button>
        <p className="text-red-500">{errMsg}</p>
        </div>
        </div>
        </Modal>

    </div>
)
}
export default CreateRoom