import React, { useState} from "react";
import RoomPanel from "./RoomPanel";
import Layout from "./Layout";
import ChatPanel from "./ChatPanel";

const App = () => {

  const [ roomName, setRoomName ] = useState()
  const [ rooms, setRooms ] = useState(['#General','#Bussiness', '#Funny'])
 

    return (
      <Layout>
      <div className="flex bg-backgroundColor items-center">
        <div className="w-1/4">
          <RoomPanel setRoomName={setRoomName} rooms={rooms} setRooms={setRooms}/>
        </div>
        
        <ChatPanel roomName={roomName} />
      </div>
      </Layout>
    );
}

export default App;