/* eslint-disable react/prop-types */
import React, {useEffect, useState} from "react"
import ReceivedMessages from "./ReceivedMessages";
import SendMessage from "./SendMessage";
import io from "socket.io-client";
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';

const socket = io(process.env.REACT_APP_BACKEND_API, {transports: ['websocket']})
console.log('cc',process.env.REACT_APP_BACKEND_API)

const ChatPanel = ({roomName}) => {
    const [ nickname, setNickname ] = useState()
    const [ message, setMessage]= useState('')
    const [ receivedMessages, setReceivedMessages ] = useState([])

    useEffect(()=>{
        // receive message
        socket.on("chat message", ({nickname,msg}) => {
          setReceivedMessages(chat=>[...chat, { nickname, msg}])
        });
      },[])
    
    useEffect(()=>{
        setNickname(localStorage.getItem('nickname'))
       },[])

    return (
        <div className=" w-3/4 flex flex-col items-center">
        <div className="bg-white w-1/2 rounded-md shadow-md m-6">
        {roomName ? <>
        <h1 className="text-2xl shadow-md rounded my-4 pb-2">{roomName || 'Undefined'}</h1>
       <ReceivedMessages receivedMessages={receivedMessages} nickname={nickname}/>
       <SendMessage socket={socket} roomName={roomName} message={message} setMessage={setMessage} nickname={nickname}/>
       </>
       : <div className="flex justify-around p-6">
           <MeetingRoomIcon fontSize="large"/>
            <h2 className="text-xl" ><i>{nickname}</i> please choose room or create new to start chatting ...</h2>
           </div> }
       </div> 
        </div>
    )
}
export default ChatPanel