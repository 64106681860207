/* eslint-disable react/prop-types */
import React, { useRef,useEffect } from 'react'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
const ReceivedMessages = ({receivedMessages,nickname}) => {

  // make a mock div in the end of all messages and scroll to that element
 const AlwaysScrollToBottom = () => {
   const scrollRef = useRef()
   useEffect(()=>{
     scrollRef.current.scrollIntoView({behaviour:'smooth'})
   },[])
   return (
     <div ref={scrollRef}>
     </div>
   )
 }
    return (
        <div className=" h-80 overflow-y-auto ">
            {receivedMessages.map((msg,index)=>{ return (
          <div className={`${msg.nickname!== nickname ? ' justify-end': ' justify-start'} text-left flex mb-3 mx-2`} key={index}>
            <AccountCircleIcon fontSize="large" className="mt-2"/>
            <div className={`ml-3 p-1 ${msg.nickname!== nickname ? ' bg-messageColor text-white': ' bg-gray-200'} rounded-lg w-1/2`}>
            <span className={` ${msg.nickname!== nickname ? ' text-white': ' text-gray-700'} text-sm font-light `}>{msg.nickname}: </span>
            <p className="p-0">{msg.msg}</p>
            </div>
          </div>
        )
        })}
          <AlwaysScrollToBottom />
        </div>
    )
}
export default ReceivedMessages